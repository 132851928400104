<template>
	<div>
		<nav class="main-nav fix-on-scroll" :class="{ 'show-mobile-nav': showMobileNav }">
			<div class="row">
				<div class="columns column12">
					<div class="nav-bar">
						<nuxt-link class="logo" :to="{ path: localePath('/', locale), hash: '#top' }" />
						<div class="nav-content">
							<ul>
								<li class="menu-close" @click="hideMobileNav">
									<font-awesome-icon class="icon icon-close" icon="fal fa-times" size="1x" />
								</li>
								<li v-for="item in topmenu[locale]" :key="item.filename" class="nav-item">
									<span @click="hideMobileNav">
										<nuxt-link :to="item.filename">
											{{ item.header }}
										</nuxt-link>
									</span>
									<submenu
										v-if="item.submenu.length"
										:subitems="item.submenu"
										@hide-mobile-nav="hideMobileNav"
									/>
								</li>
								<li class="lang-nav mobile-only">
									<div v-for="item in langmenu" :key="item.filename" class="language">
										<nuxt-link
											class="flag"
											:class="item.language"
											:to="item.filename"
											@click="hideMobileNav"
										>
											<span>{{ item.language }}</span>
										</nuxt-link>
									</div>
								</li>
							</ul>
						</div>
						<div class="buttons-wrapper">
							<social-media :socials="socials" />
							<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
								<font-awesome-icon class="icon icon-phone" icon="far fa-phone" size="1x" />
							</a>
							<a :href="`mailto:${defaults[locale].website.email}`">
								<font-awesome-icon class="icon icon-mail" icon="far fa-envelope" size="1x" />
							</a>
							<div class="lang-nav desktop">
								<font-awesome-icon class="icon icon-lang" icon="far fa-globe" size="1x" />
								<select v-if="langmenu.length" @change="switchLanguage($event)">
									<option
										v-for="item in langmenu"
										:key="item.language"
										:data-url="item.filename"
										:value="item.language"
										:selected="item.language == locale"
									>
										{{ item.language }}
									</option>
								</select>
							</div>
							<div class="buttons">
								<div class="menu-button mobile-only" @click="toggleMobileNav">
									<font-awesome-icon class="icon" icon="fal fa-bars" size="1x" />
									<span>{{ $t('buttonMenu') }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const localePath = useLocalePath();

await fetchPage();
await fetchDefaults();

const showMobileNav = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleMobileNav = () => {
	showMobileNav.value = !showMobileNav.value;
};

const hideMobileNav = () => {
	showMobileNav.value = false;
};

const switchLanguage = async (event) => {
	const option = event.target.options[event.target.selectedIndex];
	await navigateTo(option.dataset.url + window.location.hash);
};
</script>

<style lang="scss" scoped>
@media (min-width: 1201px) {
	.nav-item:hover :deep(.submenu ul) {
		display: block;
	}
}

.mobile-only {
	display: none !important;
}

.buttons-wrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;

	> a {
		margin: 0 8px;
	}

	.socials {
		margin: -4px 5px 0 0;

		a {
			opacity: 0.8;
		}
	}

	.socials,
	a {
		color: #fff;
		font-size: 20px;

		&:hover {
			color: $cta-color;
		}
	}
}

.main-nav {
	z-index: 1000;
	top: 0;
	padding: 15px 0;
	position: fixed;
	width: 100%;
	transition: all 0.3s ease-in-out;

	.logo {
		background: url('~/assets/images/logo-helling7-full-white.png') no-repeat center center;
		background-size: 89px;
		width: 89px;
		height: 94px;
		transition: all 0.3s ease-in-out;
	}

	&.fixed,
	&.noslider {
		background-color: $primary-background-color;
		box-shadow: 0 0 20px rgb(0 0 0 / 10%);

		.nav-content {
			.nav-item a {
				color: $header-color;

				&.router-link-exact-active,
				&:hover {
					color: $cta-color;
				}
			}
		}

		.logo {
			background: url('~/assets/images/logo-helling7-full-black.png') no-repeat center center;
			background-size: 68px;
			width: 68px;
			height: 71px;
		}
	}

	ul li {
		display: inline-block;
		padding: 5px 0;
	}
}

.lang-nav {
	&.desktop {
		padding: 5px 0 5px 13px;
		margin: 0 0 0 2px;
		border-left: 1px solid #474e4b;
	}

	.icon {
		font-size: 16px;
		margin: 0 5px 0 0;
	}

	select {
		width: auto;
		padding: 0;
		margin: 0 20px 0 0;
		cursor: pointer;
		appearance: none;
		background: url('~/assets/images/icon-dropdown-white.png') right 5px top 4px no-repeat;
		background-size: 6px;
		min-width: 34px;
		border: none;
		text-transform: uppercase;
		font-size: 19px;
		background-color: none;
		color: #fff;
		font-family: $heading-font-family;
		font-weight: 700;
		letter-spacing: 2px;
	}

	option {
		color: #474e4b;
	}

	svg {
		color: #fff;
	}
}

.fixed {
	.lang-nav {
		select,
		option,
		svg {
			color: #474e4b;
		}

		select {
			background: url('~/assets/images/icon-form-dropdown.png') right 5px top 4px no-repeat;
			background-size: 6px;
		}
	}

	.buttons-wrapper {
		.socials,
		a {
			color: #474e4b;

			&:hover {
				color: $cta-color;
			}
		}
	}
}

.nav-bar {
	display: flex;
	align-items: center;
	padding: 3px 0;
	z-index: 9;
	position: relative;

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			padding: 15px 25px;
			margin: 0;
			display: block;
			justify-content: center;
			align-items: center;

			i,
			svg {
				margin-right: 7px;
			}
		}

		.icon {
			display: none;
			font-size: 13px;
			margin-right: 5px;
		}
	}
}

.menu-button {
	cursor: pointer;
}

.nav-content {
	margin-right: 30px;
	flex-grow: 1;
	text-align: center;

	.nav-item a {
		text-decoration: none;
		font-weight: 500;
		margin: 0 15px;
		padding: 4px 0;
		letter-spacing: 1px;
		font-family: $heading-font-family;
		text-transform: uppercase;
		color: #fff;
		transition: all 0.3s ease-in-out;

		&.router-link-exact-active,
		&:hover {
			color: $cta-color;
		}
	}

	.menu-close {
		color: #000;
		display: none;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 15px;
		width: auto;
		right: 20px;
		padding: 0;
		margin: 0;
	}

	.lang-nav {
		margin: 25px auto;
		padding: 15px 0 0;
		display: inline-block;
		max-width: 400px;

		.language {
			margin: 20px 0;
		}

		a {
			font-size: 15px;
			color: $body-color;

			&.router-link-exact-active,
			&:hover {
				color: $cta-color;
			}
		}

		img {
			max-width: 16px;
			margin: -2px 5px 0 0;
			vertical-align: middle;
		}
	}
}

@media (max-width: 1200px) {
	.lang-nav.desktop {
		display: none;
	}

	.mobile-only {
		display: flex !important;

		&.lang-nav {
			display: inline-block !important;
		}
	}

	.nav-bar .buttons .icon {
		display: unset;
	}

	.main-nav {
		box-shadow: 0 0 10px rgb(0 0 0 / 5%);

		&.show-mobile-nav {
			.nav-content ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: left;
				padding: 0 40px;
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease-in-out;

				li:not(.menu-close) {
					width: 100%;
					max-width: 100%;
				}

				li:not(.lang-nav, .menu-close) {
					margin: 0;

					a {
						font-size: 20px;
						color: #2a282f;
						margin: 0;

						&:hover,
						&.active {
							color: $cta-color;
						}
					}
				}
			}
		}

		ul {
			position: fixed;
			inset: 0;
			background: url('~/assets/images/navigation-bottom-img.png') no-repeat center bottom
				$primary-background-color;
			background-size: 100%;
			text-align: center;
			padding: 20px 0;
			opacity: 0;
			visibility: hidden;
			overflow-y: scroll;
		}
	}

	.nav-content .menu-close {
		display: block;
	}

	.nav-content .lang-nav .language {
		margin: 0;
	}

	.logo {
		h3 {
			font-size: 22px;
		}

		h4.small {
			font-size: 18px;
		}
	}

	.menu-button {
		span {
			display: none;
		}

		svg {
			font-size: 25px !important;
			color: #fff;
			margin: 0 0 0 10px;
		}
	}

	.fixed {
		.menu-button {
			svg {
				color: #000;
			}
		}
	}
}

@media (max-width: 880px) {
	.nav-bar .buttons .button {
		padding: 16px;
	}
}

@media (max-width: 580px) {
	.nav-bar .buttons .button {
		.icon,
		i,
		svg {
			margin-right: 0;
			font-size: 16px;
		}
	}

	.nav-bar .buttons span {
		display: none;
	}

	.nav-bar .logo {
		font-size: 22px;
	}
}
</style>
